// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

// Initialize Firebase
const app = initializeApp({
	apiKey: "AIzaSyAqj5bkC3v-QJ_luI8CLHnb-gE8CuvPt0k",
	authDomain: "dvfolio.firebaseapp.com",
	databaseURL: "https://dvfolio-default-rtdb.firebaseio.com",
	projectId: "dvfolio",
	storageBucket: "dvfolio.appspot.com",
	messagingSenderId: "535518302748",
	appId: "1:535518302748:web:ef0a785fe75c6170f9c5d9",
});

export const auth = getAuth(app);
export const functions = getFunctions(app);
export default app;
