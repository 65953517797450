import React, { lazy, Suspense } from "react";

import { AuthProvider } from "./contexts/AuthContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import { PrivateRoute } from "./components/PrivateRoute";

import { Loading } from "./components/Loading";


//const AuthProvider = lazy(() => import("./contexts/AuthContext"));
const Home = lazy(() => import("./pages/Home"));
const Ssr = lazy(() => import("./pages/Ssr"));
const CorpApp = lazy(() => import("./pages/CorpApp"));
const Login = lazy(() => import("./pages/Login"));
const About = lazy(() => import("./pages/About"));
const Error404 = lazy(() => import("./pages/errors/Error404"));

function App() {
	return (
		<Router>

			<Suspense fallback={<Loading />}>
				<AuthProvider>
					<HelmetProvider>
						<Routes>
							<Route path="/login" element={<Login />}></Route>
							<Route
								path="/"
								element={
									<PrivateRoute>
										<Home />
									</PrivateRoute>
								}
							/>
							<Route
								path="/about"
								element={
									<PrivateRoute>
										<About />
									</PrivateRoute>
								}
							/>
							<Route
								path="/ssr"
								element={
									<PrivateRoute>
										<Ssr />
									</PrivateRoute>
								}
							/>
							<Route
								path="/corp-app"
								element={
									<PrivateRoute>
										<CorpApp />
									</PrivateRoute>
								}
							/>
							{/* Fallback any route to 404 */}
							<Route path="*" element={<Error404 />}></Route>
						</Routes>
					</HelmetProvider>
				</AuthProvider>
			</Suspense>
		</Router>
	);
}

export default App;
