import React, { useContext, useState, useEffect } from "react";
import { auth } from "../components/FirebaseConfig";
import {
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	signInAnonymously,
	signOut,
	sendPasswordResetEmail,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

const AuthContext = React.createContext();

export function useAuth() {
	return useContext(AuthContext);
}
var globalUserClaims = null;
export function AuthProvider({ children }) {
	const [currentUser, setCurrentUser] = useState();
	const [currentClaims, setCurrentClaims] = useState();
	const [loading, setLoading] = useState(true);

	async function promoteUser(inviteCode) {
		const functions = getFunctions();
		console.log("Promoting User...", auth.currentUser?.uid, " : ", inviteCode);
		try {
			const addMessage = httpsCallable(functions, "addContentViewerRole");
			await addMessage({ uid: auth.currentUser?.uid, key: inviteCode }).then(
				(result) => {
					// Read result of the Cloud Function.

					const data = result.data;
					console.log("Promote result:", data);
					refreshToken();
					//return data;
					//const sanitizedMessage = data.text;
				}
			);
		} catch (err) {
			console.log("Error during promote: ", err);
		}
	}
	async function refreshToken() {
		try {
			if (auth.currentUser) {
				console.log("token refreshed", auth.currentUser);
				await auth.currentUser.getIdToken(true);
				auth.currentUser.getIdTokenResult(true).then((idTokenResult) => {
					// Confirm the user is an Admin.
					if (!!idTokenResult.claims) {
						setCurrentClaims(() => idTokenResult.claims);
						globalUserClaims = idTokenResult.claims;
						//currentClaims = idTokenResult.claims;

						console.log("userclaims token:", idTokenResult.claims);

						//console.log("currentClaims", globalUserClaims);
						// Show admin UI.
					} else {
						// Show regular user UI.
					}
				});
			}
		} catch (err) {
			console.log("Error refreshing token: ", err);
		}
	}

	function getCurrentClaims() {
		return globalUserClaims;
	}

	function getContentViewable() {
		console.log("currentClaims", globalUserClaims);
		return globalUserClaims?.contentViewable;
	}

	function signup(email, password) {
		return createUserWithEmailAndPassword(auth, email, password);
	}

	function login(email, password) {
		return signInWithEmailAndPassword(auth, email, password);
	}
	function loginAnonymously() {
		return signInAnonymously(auth);
	}

	function logout() {
		return signOut(auth);
	}

	function resetPassword(email) {
		return sendPasswordResetEmail(auth, email);
	}

	function updateEmail(email) {
		return currentUser.updateEmail(email);
	}

	function updatePassword(password) {
		return currentUser.updatePassword(password);
	}

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			setCurrentUser(user);

			/* if (user) {
				console.log("user is logged in:");
				refreshToken();
			} */

			setLoading(false);
		});

		return unsubscribe;
	}, []);

	const value = {
		currentUser,
		currentClaims,
		refreshToken,
		promoteUser,
		getCurrentClaims,
		getContentViewable,
		login,
		loginAnonymously,
		signup,
		logout,
		resetPassword,
		updateEmail,
		updatePassword,
	};

	return (
		<AuthContext.Provider value={value}>
			{!loading && children}
		</AuthContext.Provider>
	);
}
